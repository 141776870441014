
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Footer from "@/components/Footer.vue";
import PaymentCard from "@/components/PaymentCard.vue";
import Tooltip from "primevue/tooltip";
import NotificationCard from "@/components/Notification-Card.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Utils from '@/utils/utils';
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "Invoice",
  components: {
    Card,
    Calendar,
    Dropdown,
    Footer,
    PaymentCard,
    NotificationCard,
    InputText,
    Button,
    DataTable,
    Column,
    Badge,
    LoadingSpinner
  },
  directives: {
    tooltip: Tooltip,
  },
  data() {
    return {
      totalRecords: 0,
      maxExportRows: 1000,
      rangeStart: 1,
      rangeEnd: 10,
      isLoadingTickets: false,
      invoicesLoaded: false,
      primaryColor: "#ff6600",
      page: 1,
      first: 0,
      rows: 50,
      dueDateFilter: null,
      selectedType: null,
      selectedStatus: "O",
      invoiceNumber: "",
      dateSortActive: true,
      balSortActive: false,
      displayPayment: false,
      windowSize: window.innerHeight,
      allExportColumns: [
        { field: "ar_id", header: "Invoice", input: true },
        { field: "cust_name", header: "Customer Name", input: true },
        { field: "sortableDate", header: "Due Date", input: true },
        { field: "type", header: "Type", input: true },
        { field: "invoice_amt", header: "Invoice Amount", input: true },
        { field: "balance", header: "Balance", input: true },
        { field: "status", header: "Status", input: true },
      ],
      dynamicColumns: [
        { field: "ar_id", header: "Invoice", input: true },
        { field: "cust_name", header: "Customer Name", input: true },
        { field: "sortableDate", header: "Due Date", input: true },
        { field: "type", header: "Type", input: true },
        { field: "invoice_amt", header: "Invoice Amount", input: true },
        { field: "balance", header: "Balance", input: true },
        { field: "status", header: "Status", input: true },
      ],
      stati: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      sort: {
        sortBy: "",
        sortOrder: 0,
      },
      statCards: [
        {
          title: "Outstanding Invoices Count 1",
          number: 25,
        },
        {
          title: "Outstanding Invoices Count 2 ",
          number: 25,
        },
        {
          title: "Outstanding Invoices Count 3",
          number: 25,
        },
      ],
      columnStyle: "col-12 md:col-1 md:flex-grow-1 lg:col-1 lg:flex-grow-1",
      hover: {},
      filters: {
        status: { value: [], matchMode: FilterMatchMode.IN, },
        type: { value: [], matchMode: FilterMatchMode.IN, },
        ar_id: { value: "", matchMode: FilterMatchMode.EQUALS, },
        sortableDate: { value: [], matchMode: FilterMatchMode.BETWEEN, }
      },
    };
  },
  computed: {
    ...mapState(["invoice", "customer", "payment", "session"]),
    ...mapGetters({
      getRange: "invoice/getRange",
      getPage: "invoice/getPage",
      getLength: "invoice/getLength",
      getInvoices: "invoice/getInvoices",
      getCust: "customer/getCust",
      getFirstCust: "session/getFirstCust",
      getInvoice: "invoice/getInvoice",
      getLoadingInvoices: "invoice/getLoadingInvoices",
      getLoadingStatus: "invoice/getLoadingStatus",
      getAR: "control/getAR",
    }),
    resizeRight() {
      if (this.windowSize < 769) {
        return "text-center";
      }
      return "text-right";
    },
    resizeLeft() {
      if (this.windowSize < 769) {
        return "text-center";
      }
      return "text-left";
    },
    typesForSearch(): string[] {
      const codes = this.types.map(type => type.code);
      return codes;
    },
  },
  async created() {
    const control = {
        id: "AR1", // ToDo: When multi-company is setup, this will need to be dynamic
        procedure: "AR.CONTROL",
        filename: "CONTROL",
        fieldnames:"ar_type_name|ar_type_code|visible",
      };
    await this.fetchControls(control);
    this.setVisibleARTypes();
      this.fetchFilteredInvoices(false);
  },

  methods: {
    ...mapActions({
      fetchInvoice: "invoice/fetchInvoice",
      fetchControls: "control/fetchControls",
      setInvoice: "invoice/setInvoices",
      setNextPage: "invoice/setNextPage",
      addInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
      addNotification: "notification/add",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
      clearInvoicesToPay: "invoice/clearInvoicesToPay",
      setInvoices: "invoice/setInvoices",
    }),
    async printTable() {
      //block a print with no criteria applied
      if(this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchFilteredInvoices(false);
        this.setPrintData(this.getInvoices.map((item: any) => {
              return {
                ar_id: item.ar_id,
                cust_name: item.cust_name,
                due_date: item.due_date,
                balance: item.balance,
                type: this.setTypeFilter(item.type),
                status: this.setStatusFilter(item.status),
                invoice_amt: item.invoice_amt,
              };
            }));
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open(
          "/printable-view?print=1&showSelection=1",
          "_blank"
        );
      }
    },
    setStatusFilter(status: string) {
      switch(status) {
        case "O":
          return "Outstanding";
        case "P":
          return "Paid";
        case "Y":
          return "Payment Pending";
        default:
          return "NA";
      }
    },
    setTypeFilter(type: string) {
      switch(type) {
        case "CM":
          return "Credit Memo";
        case "DM":
          return "Debit Memo";
        case "IN":
          return "Invoice";
        case "OA":
          return "On Account";
        default:
          return "NA";
      }
    },
    setVisibleARTypes() {
      if (this.getAR && this.getAR.length > 0) {
        this.types = this.types.filter((type) => {
        const arEntry = this.getAR.find((ar: { ar_type_code: string; }) => ar.ar_type_code === type.code);
        return arEntry !== undefined && arEntry.visible === 'Y';
        });
      }
    },
    sortInvoices(event: any) {
      this.sort.sortOrder = event.sortOrder
      this.sort.sortBy = event.sortField
      this.fetchFilteredInvoices(false)
    },
    customerId(): string {
      if (this.getCust !== null) return this.getCust.cust_id;
      else {
        return this.getFirstCust.cust_id;
      }
    },
    formatCurrency(value: string) {
      const amount = parseFloat(value);

      if(isNaN(amount)) {
        return 'error';
      } else {
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        })
      }
    },
    formatDate(date: string) {
      return Utils.formatDate(date);
    },
    fetchInvoiceRecord(recordId: string) {
      this.fetchInvoice({
        recordId: recordId,
      });
    },

    async fetchFilteredInvoices(addInvoices: boolean) {
      this.isLoadingTickets = true;
      let rangeStart = this.rangeStart;
      let rangeEnd = this.rangeEnd;

      rangeStart = 1;
      rangeEnd = this.maxExportRows;

      let sortBy
      switch(this.sort.sortBy) {
        case "invoice_amt":
          sortBy = "invoice.amt";
          break;
        case "sortableDate":
          sortBy = "due.date";
          break;
        case "cust_name":
          sortBy = "cust";
          break;
        default:
          sortBy = this.sort.sortBy
          break;
      }
      if(this.selectedStatus) {
        this.filters.status.value = [this.selectedStatus]  as any
      } else {
        this.filters.status.value = null as any
      }
      if(this.selectedType) {
        this.filters.type.value = [this.selectedType] as any
      }
      else {
        this.filters.type.value = []
      }
      this.filters.sortableDate.value = this.dueDateFilter || []
      this.filters.ar_id.value = this.invoiceNumber

      const resp = await this.setInvoice({
        custId: this.customerId(),
        dateRange: this.dueDateFilter,
        status: this.selectedStatus,
        addInvoices: addInvoices,
        id: this.invoiceNumber,
        type: this.selectedType !== null ? this.selectedType : this.typesForSearch,
        sortOrder: this.sort.sortOrder === -1 ? "DEC" : "",
        sortBy: sortBy
      })
      
      this.totalRecords = resp.data.length;
      this.isLoadingTickets = false;

      if (!addInvoices) {
        this.page = 1;
        this.first = 0;
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    onPage(event: any) {
      this.setNextPage(event.first);
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.getLength == this.getRange - 100
      ) {
        this.fetchFilteredInvoices(true);
      }
    },
    resize(event: any) {
      this.windowSize = window.innerWidth;
    },
    invoiceIcon(id: string) {
      let downloaded = this.getInvoice;
      let downloading = this.getLoadingInvoices.includes(id);
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.invoice_num === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.invoice_num === id) !== undefined &&
          !downloading,
      };
    },
    inPayArray(id: string) {
      const found = this.invoice.invoicesToPay.find((inv: any) => {
        return inv.arId == id;
      });
      if (found) return true;

      return false;
    },
    addInvoice(data: any) {
      this.addInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
        dueDate: data.due_date,
      });
    },
    removeInvoice(data: any) {
      this.removeInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
      });
    },
  },
  watch: {
    getCust() {
      this.fetchFilteredInvoices(false);
      this.clearInvoicesToPay();

    }
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
});
