import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid justify-content-center ml-0" }
const _hoisted_2 = {
  key: 0,
  div: ""
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "grid col-12 justify-content-center" }
const _hoisted_5 = {
  class: "col-12",
  style: {"display":"flex","justify-content":"end"}
}
const _hoisted_6 = { class: "col-12 mt-1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 0,
  class: "grid mr-0 justify-content-center",
  ref: "paymentModal"
}
const _hoisted_16 = { class: "col-12 xl:col-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationCard = _resolveComponent("NotificationCard")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PaymentCard = _resolveComponent("PaymentCard")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NotificationCard),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, { class: "col-11 mb-5 mt-3" }, {
        content: _withCtx(() => [
          (_ctx.isLoadingTickets)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingTickets }, null, 8, ["loading"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Button, {
                      class: "split-sales-order-btn",
                      label: "Print/Export",
                      icon: "pi pi-print",
                      onClick: _ctx.printTable
                    }, null, 8, ["onClick"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_DataTable, {
                      class: "p-datatable-sm",
                      value: _ctx.getInvoices,
                      dataKey: "ar_id",
                      paginator: "",
                      first: _ctx.getPage,
                      "onUpdate:first": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.getPage) = $event)),
                      rows: _ctx.rows,
                      "onUpdate:rows": _cache[13] || (_cache[13] = ($event: any) => (_ctx.rows = $event)),
                      onSort: _ctx.sortInvoices,
                      sortField: _ctx.sort.sortBy,
                      sortOrder: _ctx.sort.sortOrder,
                      onPage: _ctx.onPage,
                      filterDisplay: "menu",
                      filters: _ctx.filters,
                      "onUpdate:filters": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.filters) = $event)),
                      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                      rowsPerPageOptions: [25, 50, 75],
                      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Invoices",
                      responsiveLayout: "stack",
                      breakpoint: "986px",
                      loading: _ctx.getLoadingStatus
                    }, {
                      empty: _withCtx(() => [
                        _createTextVNode(" No Invoices to display ")
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "ar_id",
                          header: "Invoice",
                          showFilterMatchModes: false,
                          showFilterOperator: false,
                          bodyStyle: "min-width: 100px"
                        }, {
                          body: _withCtx(({data}) => [
                            _createVNode(_component_Button, {
                              class: "p-button-info p-button-text p-1 pr-0",
                              onClick: ($event: any) => (_ctx.fetchInvoiceRecord(data.ar_id))
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("i", {
                                  class: _normalizeClass(_ctx.invoiceIcon(data.ar_id))
                                }, null, 2), [
                                  [
                                    _directive_tooltip,
                                    'Generate Invoice ' + data.ar_id,
                                    void 0,
                                    { left: true }
                                  ]
                                ]),
                                _createTextVNode("   " + _toDisplayString(data.ar_id), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_InputText, {
                              type: "text",
                              modelValue: _ctx.invoiceNumber,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.invoiceNumber) = $event)),
                              class: "p-column-filter",
                              placeholder: "Search by Id"
                            }, null, 8, ["modelValue"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.invoiceNumber = ''; _ctx.fetchFilteredInvoices(false)})
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Apply",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchFilteredInvoices(false)))
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "cust_name",
                          header: "Customer Name",
                          sortable: "",
                          showFilterMatchModes: false,
                          showFilterOperator: false
                        }),
                        _createVNode(_component_Column, {
                          field: "sortableDate",
                          header: "Due Date",
                          sortable: "",
                          showFilterMatchModes: false,
                          showFilterOperator: false
                        }, {
                          body: _withCtx(({data}) => [
                            _createTextVNode(_toDisplayString(_ctx.formatDate(data.due_date)), 1)
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_Calendar, {
                              modelValue: _ctx.dueDateFilter,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dueDateFilter) = $event)),
                              selectionMode: "range",
                              manualInput: false,
                              placeholder: "Select Date Range",
                              showButtonBar: true,
                              showIcon: true
                            }, null, 8, ["modelValue"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.dueDateFilter = null; _ctx.fetchFilteredInvoices(false)})
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Apply",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchFilteredInvoices(false)))
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "type",
                          header: "Type",
                          sortable: "",
                          showFilterMatchModes: false,
                          showFilterOperator: false
                        }, {
                          body: _withCtx(({data}) => [
                            _createTextVNode(_toDisplayString(_ctx.types.find(type => type.code === data.type)?.name), 1)
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.selectedType,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedType) = $event)),
                              options: _ctx.types,
                              optionLabel: "name",
                              optionValue: "code",
                              placeholder: "Filter By Type",
                              style: { width: '150px' }
                            }, null, 8, ["modelValue", "options"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => {_ctx.selectedType = null; _ctx.fetchFilteredInvoices(false)})
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Search",
                              onClick: _cache[8] || (_cache[8] = $event => _ctx.fetchFilteredInvoices(false))
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "invoice_amt",
                          header: "Invoice Amount",
                          sortable: "",
                          bodyClass: "pr-2 xl:pr-6",
                          bodyStyle: "text-align: right;"
                        }, {
                          body: _withCtx(({data}) => [
                            _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.invoice_amt)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "balance",
                          header: "Balance",
                          sortable: "",
                          bodyClass: "pr-2 xl:pr-6",
                          bodyStyle: "text-align: right;"
                        }, {
                          body: _withCtx(({data}) => [
                            _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.balance)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "status",
                          header: "Status",
                          showFilterMatchModes: false,
                          showFilterOperator: false
                        }, {
                          body: _withCtx(({data}) => [
                            (data.ach === 'Y')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_Badge, {
                                    value: "PAYMENT PENDING",
                                    severity: "info"
                                  })
                                ]))
                              : (data.status === 'O')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createVNode(_component_Badge, {
                                      value: "OUTSTANDING",
                                      severity: "danger"
                                    })
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createVNode(_component_Badge, {
                                      value: "PAID",
                                      severity: "success"
                                    })
                                  ]))
                          ]),
                          filter: _withCtx(() => [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.selectedStatus,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedStatus) = $event)),
                              options: _ctx.stati,
                              optionLabel: "name",
                              optionValue: "code",
                              placeholder: "Filter By Status",
                              style: { width: '150px' }
                            }, null, 8, ["modelValue", "options"])
                          ]),
                          filterclear: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Clear",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => {this.selectedStatus = null; _ctx.fetchFilteredInvoices(false)})
                            })
                          ]),
                          filterapply: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Search",
                              onClick: _cache[11] || (_cache[11] = $event => _ctx.fetchFilteredInvoices(false))
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, null, {
                          body: _withCtx(({data}) => [
                            (data.status === 'O')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  (!_ctx.inPayArray(data.ar_id))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        (data.ach_pending !== 'Y')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                              _createVNode(_component_Button, {
                                                label: "Click to Pay",
                                                class: "p-button-rounded text-md p-1",
                                                style: {"width":"105px"},
                                                onClick: ($event: any) => (_ctx.addInvoice(data))
                                              }, null, 8, ["onClick"])
                                            ]))
                                          : (data.ach_pending === 'Y')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                                            : _createCommentVNode("", true)
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        (!_ctx.hover[data.ar_id])
                                          ? (_openBlock(), _createBlock(_component_Button, {
                                              key: 0,
                                              value: { arId: data.ar_id, balance: data.balance },
                                              onClick: _ctx.addInvoice,
                                              icon: "pi pi-check",
                                              iconPos: "right",
                                              class: "p-button-rounded p-button-success text-md p-1",
                                              style: {"width":"105px"},
                                              label: "Selected",
                                              onMouseover: ($event: any) => (_ctx.hover[data.ar_id] = true)
                                            }, null, 8, ["value", "onClick", "onMouseover"]))
                                          : _createCommentVNode("", true),
                                        (_ctx.hover[data.ar_id])
                                          ? (_openBlock(), _createBlock(_component_Button, {
                                              key: 1,
                                              value: { arId: data.ar_id, balance: data.balance },
                                              onClick: ($event: any) => (_ctx.removeInvoice(data)),
                                              icon: "pi pi-times",
                                              iconPos: "right",
                                              class: "p-button-rounded p-button-danger text-md p-1",
                                              style: {"width":"105px"},
                                              label: "Remove",
                                              onMouseleave: ($event: any) => (_ctx.hover[data.ar_id] = false)
                                            }, null, 8, ["value", "onClick", "onMouseleave"]))
                                          : _createCommentVNode("", true)
                                      ]))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value", "first", "rows", "onSort", "sortField", "sortOrder", "onPage", "filters", "loading"])
                  ])
                ]),
                (_ctx.invoice.invoicesToPay.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_PaymentCard, {
                          status: this.selectedStatus,
                          custId: this.customerId()
                        }, null, 8, ["status", "custId"])
                      ])
                    ], 512))
                  : _createCommentVNode("", true)
              ]))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer)
  ], 64))
}