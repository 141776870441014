<template>
  <Card>
    <template #title> Payment </template>
    <template #content>
      <div class="grid">
        <div class="sm:col-12 md:col-12 lg:col-6">
          <Card>
            <template #content>
              <div v-if="payment.achAllowed">
                <div class="grid">
                  <div class="field sm:col-12 md:col-12 lg:col-12 p-0">
                    <ToggleButton
                      v-model="CC"
                      onLabel="Pay by Credit Card"
                      offLabel="Pay by Credit Card"
                      onIcon="pi pi-credit-card"
                      offIcon="pi pi-credit-card"
                      @click="
                        (ACH = false),
                          (this.route = null),
                          (this.account = null),
                          (this.type = 'Checking'),
                          (this.selectedAccount = ''),
                          (this.displayAddAccount = false),
                          (this.agreement = false)
                      "
                    />
                    &nbsp;
                    <ToggleButton
                      v-model="ACH"
                      class="mt-1 sm:mt-0"
                      onLabel="Pay by eCheck"
                      offLabel="Pay by eCheck"
                      onIcon="pi pi-wallet"
                      offIcon="pi pi-wallet"
                      @click="() => {selectedCC = ''; success = false; ccList = JSON.parse(JSON.stringify(this.getCust.credit_card_no_items || [])); CC = false; validatePaymentForm()}"
                    />
                  </div>
                </div>
                &nbsp;
              </div>
              <div v-if="this.ACH && payment.achAllowed">
                <span class="font-medium text-bluegray-700 mb-2">Select an Account</span>
                <DataTable v-model:selection="selectedAccount" :value="this.getCust.bank_routing_items" responsiveLayout="stack" breakpoint="1279px" class="p-datatable-sm lg:mt-2 bordered-table with-footer" @row-select="this.displayAddAccount = false; this.route = this.selectedAccount.bank_routing; this.account = this.selectedAccount.bank_account; this.type = this.selectedAccount.account_type">
                  <template #empty> No accounts on file. </template>
                  <Column selectionMode="single" headerStyle="width: 1rem"></Column>
                  <Column field="bank_token" header="Account #" style="width: 35%">
                  </Column>
                  <Column field="bank_routing" header="Routing #" style="width: 35%">
                  </Column>
                  <Column field="account_type" header="Type" style="min-width: 25%">
                  </Column>
                  <template #footer> <Button label="+ Use a New Account" severity="secondary" text @click="toggleUseNewAccount" class="h-2rem use-new-card text-md"/> </template>
                </DataTable>
                <div v-if="this.selectedAccount !== ''" class="field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start">
                  <Checkbox id="agreement" v-model="agreement" :binary="true" />
                  <label for="agreement"
                    >By submitting this payment, you acknowledge that you have authorized
                    {{ session.client }} to debit your account, and you accept responsibility for
                    any resulting fees.</label
                  >
                </div>
                <div v-if="this.displayAddAccount" class="col-12 grid mt-2">
                  <div class="field col-12 md:col-6 lg:col-12 xl:col-6 mb-2" style="min-width: 250px;">
                    <span class="p-float-label">
                      
                      <InputText id="route" v-model="route" @change="validatePaymentForm" :useGrouping="false" class="h-2rem" type="number"/>
                      <label for="route">Routing Number</label>
                      &nbsp;
                      <i
                        class="pi pi-info-circle"
                        v-tooltip.top="{
                          value:
                            '<img src=&quot;./images/check.png&quot; height=&quot;150&quot; width=&quot;278&quot;>',
                          escape: true,
                        }"
                      ></i>
                    </span>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-12 xl:col-6 mb-0" style="min-width: 250px;">
                    <span class="p-float-label">
                      <InputNumber id="account" v-model="account" :useGrouping="false" class="h-2rem"/>
                      <label for="account">Account Number</label>
                    </span>
                  </div>
                  <div class="field-radiobutton col-6 md:col-6 lg:col-6">
                    <RadioButton id="checking" name="type" value="Checking" v-model="type" />
                    <label for="checking">Checking</label>
                  </div>
                  <div class="field-radiobutton col-6 md:col-6 lg:col-6">
                    <RadioButton id="savings" name="type" value="Savings" v-model="type" />
                    <label for="savings">Savings</label>
                  </div>
                  <div  class="field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start">
                    <Checkbox id="agreement" v-model="agreement" :binary="true" />
                    <label for="agreement"
                      >By submitting this payment, you acknowledge that you have authorized
                      {{ session.client }} to debit your account, and you accept responsibility for
                      any resulting fees.</label
                    >
                  </div>
                  <div  class="field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start">
                    <Checkbox id="saveAccount" v-model="saveAccount" :binary="true" />
                    <label for="saveAccount"
                      >Save this account for future use</label
                    >
                  </div>
                </div>
              </div>
              <div v-if="this.ACHFee  > 0 && this.ACH" class="justify-content-center">A fee of {{ this.ACHFee }}% will be added to your ACH Payment.</div>
              <div v-if="this.CC || !payment.achAllowed">
                <span class="font-medium text-bluegray-700 mb-2">Select a Credit Card</span>
                <DataTable v-model:selection="selectedCC" :value="this.ccList" responsiveLayout="stack" breakpoint="1279px" class="p-datatable-sm mt-0 lg:mt-2 bordered-table with-footer" @row-select="this.displayIframe = false; this.success=true;">
                  <template #empty> No credit cards on file. </template>
                  <Column selectionMode="single" headerStyle="width: 2rem"></Column>
                  <Column field="cardholder_name" header="Name" style="width: 40%"></Column>
                  <Column field="credit_card_no" header="Credit Card Number" style="width: 35%">
                    <template #body="slotProps">
                      {{ formatCardNumber(slotProps.data.credit_card_id) }}
                    </template>
                  </Column>
                  <Column field="credit_card_exp" header="Expiry Date" style="width: 4rem">
                    <template #body="slotProps">
                      {{ formatExpDate(slotProps.data.credit_card_exp) }}
                    </template>
                  </Column>
                  <Column>
                    <template #body="slotProps">
                      <Button
                        icon="pi pi-trash"
                        class="p-button-sm mb-2 xl:mb-0"
                        :style="{ backgroundColor: 'red', padding: '2px !important', width: '26px'}"
                        @click="handleShowConfirmDeleteCC(slotProps.data)"
                      />
                    </template>
                  </Column>
                  <template #footer> <Button label="+ Use a New Card" severity="secondary" text @click="toggleUseNewCard" class="h-2rem use-new-card text-md" /> </template>
                </DataTable>
                <div
                  class="sm:col-12 md:col-12 lg:col-12"
                  v-if="this.displayIframe && !this.success"
                >
                  <iframe
                    v-resize="{
                      log: false,
                      heightCalculationMethod: 'documentElementScroll',
                    }"
                    :src="payment.iframeurl"
                    class="w-full"
                    id="iframe"
                    frameborder="0"
                  >
                  </iframe>
                </div>
                <div v-if="this.CCFee > 0 && this.CC" class="justify-content-center text-center">A fee of {{ this.CCFee }}% will be added to your Credit Card Payment.</div>
                <div
                  class="sm:col-12 md:col-12 lg:col-12 text-center"
                  v-if="this.success && !this.displayIframe && !this.orderSuccess && this.selectedCC.credit_card_id === this.token"
                >
                  <b><span class="text-green-700">Credit Card Validation Succesful</span></b>
                  <div class="mt-2">
                    <Checkbox inputId="saveCC" v-model="saveCC" :binary="true"></Checkbox>
                    <label for="saveCC" class="ml-2">Save this credit card for future use</label>
                  </div>
                  <div v-if="saveCC" class="flex justify-content-center save-cc">
                      <span class="flex mt-2 p-float-label">
                        <InputText id="ccName" v-model="ccName" :useGrouping="false" class="h-2rem" :style="{width: '270px'}" @change="this.ccList[ccList.length - 1].cardholder_name = ccName; this.selectedCC.cardholder_name = ccName"/>
                        <label for="ccName">Credit Card Name</label>
                      </span>
                    </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="sm:col-12 md:col-12 lg:col-6">
          <Card>
            <template #content>
              <div class="grid align-items-start justify-content-center">
                <template v-if="this.displayOrderSubmit">
                  <div class="col-10">
                    <strong>Invoices</strong>
                    <div
                      v-for="inv in invoice.invoicesToPay"
                      v-bind:key="inv.arId"
                      class="justify-content-between m-t2 flex"
                    >
                      <span class="block"> {{ inv.arId }} {{ `(${inv.dueDate})` }}: </span
                      ><span class="block"
                        >{{
                          inv.balance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        <span class="pi pi-times pay-icon" @click="this.removeInvoice(inv)"></span
                      ></span>
                    </div>
                    <Divider class="mt-1" />
                    <div
                      class="font-semibold"
                      style="color: #d32f2f; text-align: right; padding-right: 20px"
                    >
                      TOTAL:
                      {{
                        this.total.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </div>
                  </div>

                  <div class="col-10">
                    <Button
                      label="Submit Payment"
                      :icon="this.payment.loading ? 'pi pi-spin pi-spinner' : ''"
                      @click="submitPayment"
                      :disabled="
                        (!success &&
                          ((this.route == null || v$.route.$error) ||
                            this.account == null ||
                            this.type == null ||
                            this.agreement == false)) ||
                            this.total <= 0 ||
                        this.payment.loading
                      "
                    />
                    <div class="p-error" v-if="v$.$errors != null && v$.$errors?.length > 0 && (!this.CC && v$.$errors[0].$message === 'Please enter a routing number')">
                     {{ v$.$errors[0].$message }}
                    </div>
                    <div v-if="this.successBitArr[0] == 1">
                      <div class="col-12 text-pink-900 text-center">
                        <i class="pi pi-times-circle text-2xl"> </i>
                        <span class="ml-1">{{ this.error }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else-if="this.successBitArr[1] == 1">
                  <div class="col-12 text-green-900 text-center">
                    <i class="pi pi-check-circle text-2xl"></i>
                    <div>Payment Submitted Succesfully</div>
                    <div>Record Id : {{ payment.recordId }}</div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </template>
  </Card>

  <MessageBox 
    :showDialog="showConfirmDeleteCC"
    icon="WARNING"
    :header="`Removing Credit Card ${ccToDelete.cardholder_name ? ccToDelete.cardholder_name.toUpperCase() : ''}`"
    message="Are you sure you want to delete this credit card?"
    :messageItems="[{ 'Credit Card Ending In': formatCardNumber(ccToDelete.credit_card_id), 'Expiry Date': formatExpDate(ccToDelete.credit_card_exp) }]"
    primaryButton="Yes"
    cancelButton="Cancel"
    @primaryButtonClick="handleDeleteCreditCard(ccToDelete)"
    @cancelButtonClick="showConfirmDeleteCC = false; this.ccToDelete = {}"
  />
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import Card from "primevue/card";
import Button from "primevue/button";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Divider from "primevue/divider";
import ToggleButton from "primevue/togglebutton";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import useVuelidate from "@vuelidate/core";
import { numeric, minLength, maxLength, sameAs, minValue, helpers, required, requiredIf } from "@vuelidate/validators";
import DataTable from "primevue/datatable";
import MessageBox from "./MessageBox.vue";

export default defineComponent({
  components: {
    Card,
    Button,
    Divider,
    ToggleButton,
    InputNumber,
    InputText,
    Checkbox,
    RadioButton,
    DataTable,
    Column,
    MessageBox,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  async created() {
    await this.fetchControls({
      Client: "",
      id: "AR1", // we may need to utilize the company code for the active customer?
      procedure: "AR.CONTROL",
      filename: "CONTROL",
      getter: "control/getACHFee",
      });
      // update the ACHFee value if getACHFee is defined and not null
      this.ACHFee = this.getACHFee != null ? this.getACHFee : 0;
     await this.fetchControls({
      Client: "",
      id: "CC", 
      procedure: "CC.CONTROL",
      filename: "CONTROL",
      getter: "control/getCCFee",
      });
      // update the CCFee value if getCCFee is defined and not null
      this.CCFee = this.getCCFee != null ? this.getCCFee : 0;
      this.ccList = JSON.parse(JSON.stringify(this.getCust.credit_card_no_items || []));
      this.ccList = this.ccList.filter((card) => card.credit_card_id && card.credit_card_id.trim());
  },
  validations(){
    return {
      route: { 
        required: helpers.withMessage("Please enter a routing number", required),
        minLength: helpers.withMessage("Routing number should be at least 8 characters long", minLength(8)),
        maxLength: helpers.withMessage("Routing number max length is 9 characters long", maxLength(9)),
        numeric: helpers.withMessage("Routing number should be numeric", numeric)
      },
      account: {
        required: helpers.withMessage("Account number is required", required)
      },
      agreement: {
        sameAs: helpers.withMessage("Please accept the agreement", sameAs(true))
      },
      total: {
        minValue: helpers.withMessage("Total amount cannot be a negative value", minValue(0))
      },
    }
  },
  directives: {
    tooltip: Tooltip,
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  data() {
    return {
      showConfirmDeleteCC: false,
      ccToDelete: {},
      ccName: "",
      selectedAccount: "",
      ccList: [],
      displayAddAccount: false,
      saveAccount: true,
      saveCC: true,
      selectedCC: "",
      success: false,
      expiry: "",
      token: "",
      tokenMessage: "",
      displayIframe: false,
      displayOrderSubmit: true,
      orderSuccess: false,
      error: "",
      successBitArr: [0, 0],
      ACH: false,
      CC: true,
      route: null,
      type: "Checking",
      account: null,
      agreement: false,
      types: ["Checking", "Savings"],
      ACHFee: 0,
      CCFee: 0,
    };
  },
  props: {
    status: String,
    custId: String,
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
  },
  methods: {
    ...mapActions({
      postPayment: "payment/postPayment",
      clearInvoicesToPay: "invoice/clearInvoicesToPay",
      setInvoices: "invoice/setInvoices",
      postACH: "payment/postACH",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
      addNotification: "notification/add",
      fetchControls: "control/fetchControls",
      setCust: "customer/setCustomer",
      updateCustomer: "customer/updateCustomer",
      fetchCustomer: "customer/fetchCustomer",
    }),
    handleShowConfirmDeleteCC(data) {
      this.showConfirmDeleteCC = true;
      this.ccToDelete = data;
    },
    async handleDeleteCreditCard(data) {
      this.ccList = this.ccList.filter((el) => el.credit_card_id !== data.credit_card_id);
      const oldCustomer = JSON.parse(JSON.stringify(this.getCust));
      this.getCust.credit_card_no_items = this.ccList;
      const newCustomer = JSON.parse(JSON.stringify(this.getCust));

      await this.updateCustomer({
        id: this.getCust.cust_id,
        client: this.session.client,
        oldCust: oldCustomer,
        newCust: newCustomer,
      });
      this.showConfirmDeleteCC = false;
    },
    toggleUseNewCard() {
      this.displayIframe = !this.displayIframe;
      if(!this.success) {
        this.selectedCC = "";
        this.token = "";
      }
    },
    toggleUseNewAccount() {
      this.displayAddAccount = !this.displayAddAccount; 
      this.selectedAccount = ''; 
      this.route = null; 
      this.account = null; 
      this.type = 'Checking'
    },
    formatExpDate(dateString) {
      if(dateString) {
        if (!dateString?.includes("/"))
          return dateString[0] + dateString[1] + "/" + dateString[2] + dateString[3];
        return dateString;
      }
      
    },
    formatCardNumber(card_id) {
      return "************" + card_id?.substring(12, 16);
    },
    async validatePaymentForm(){
      await this.v$.$validate()
    },
    submitPayment() {
      if (this.CC) {
        let li = 1
        let invoices = []
        this.invoice.invoicesToPay.forEach((ar) => {
          invoices.push({li: li.toString(), arid: ar.arId, amount: parseFloat(ar.balance).toFixed(2).toString(), ar_app_amt: parseFloat(ar.balance).toFixed(2).toString() })
          li++
        })
        let creditcard_no_items = [{credit_card_id: this.selectedCC.credit_card_id, creditcard_exp: this.selectedCC.credit_card_exp, creditcard_amt: this.total.toString(), cardholder_name: this.selectedCC.cardholder_name}]
        let saveFlag = false
        if(this.getCust.credit_card_no_items?.length !== this.ccList.length && this.saveCC) {
          saveFlag = true
        }
        //TODO: Add meta data obj to payload
        this.postPayment({
          meta:{save_payment: saveFlag},
          check_amount: this.total.toString(),
          cust: this.custId,
          li_items: invoices,
          payment_type: 'CC',
          creditcard_no_items: creditcard_no_items,
          receipt_email_address:  this.getUser && this.getUser.email_address_items && this.getUser.email_address_items.length > 0 ? this.getUser.email_address_items[0].email_address : "",
        }).then((response) => {
          if (response.error && response.error.startsWith("Changes were made to the")) {
            this.orderSuccess = false;
            this.error = response.error + " Page will now refresh for accurate data.";
            this.displayOrderSubmit = true;
            this.successBitArr[0] = 1;
            setTimeout(() => {
              this.clearInvoicesToPay();
              this.setInvoices({
                custId: this.custId,
                dateRange: null,
                status: this.status,
                sortBy: "",
                addInvoices: false,
                id: null,
              });
              this.successBitArr = [0, 0];
            }, 3000);
          } else if (response.error) {
            this.orderSuccess = false;
            this.error = response.error;
            this.displayOrderSubmit = true;
            this.successBitArr[0] = 1;
          } else {
            this.orderSuccess = true;
            this.displayOrderSubmit = false;
            this.successBitArr[1] = 1;
            if(saveFlag) {
              //update cust
              this.fetchCustomer({
                id: this.getCust.cust_id,
              });
            }
              this.ccName = "";
              this.selectedCC = "";
            setTimeout(() => {
              //refreshes the invoices to get accurate data that represents the newly paid invoices
              this.setInvoices({
                custId: this.custId,
                dateRange: null,
                status: this.status,
                sortBy: "",
                addInvoices: false,
                id: null,
              });
              this.successBitArr = [0, 0];
              this.clearInvoicesToPay();
            }, 4000);
          }
        });
      } else if (this.ACH) {

        let saveFlag = false

        if(this.getCust.bank_routing_items?.find(el => String(el.bank_account) === String(this.account)) === undefined && this.saveAccount) {
          saveFlag = true
        }

        let tempACH = false;
        if(this.getCust.bank_routing_items?.find(el => el.bank_account === String(this.account)) === undefined && !this.saveAccount) {
          tempACH = true
        }
        //TODO: Add meta data obj to payload
        this.postACH(
          {
          meta:{save_payment: saveFlag, temp_ach: tempACH},
          amount: this.total,
          cust: this.custId,
          invoices: this.invoice.invoicesToPay,
          route: String(this.route),
          account: String(this.account),
          type: this.type,
          Client: this.session.client,
          receipt_email_address:  this.getUser && this.getUser.email_address_items && this.getUser.email_address_items.length > 0 ? this.getUser.email_address_items[0].email_address : "",
        }).then((response) => {
          if (response.error && response.error.startsWith("Changes were made to the")) {
            this.orderSuccess = false;
            this.error = response.error + " Page will now refresh for accurate data.";
            this.displayOrderSubmit = true;
            this.successBitArr[0] = 1;
            setTimeout(() => {
              this.clearInvoicesToPay();
              this.setInvoices({
                custId: this.custId,
                dateRange: null,
                status: this.status,
                sortBy: "",
                addInvoices: false,
                id: null,
              });
              this.successBitArr = [0, 0];
            }, 3000);
          } else if (response.error) {
            this.orderSuccess = false;
            this.error = response.error;
            this.displayOrderSubmit = true;
            this.successBitArr[0] = 1;
          } else {
            this.orderSuccess = true;
            this.displayOrderSubmit = false;
            this.successBitArr[1] = 1;

            if(saveFlag) {
              //update cust
              this.fetchCustomer({
                id: this.getCust.cust_id,
              });
            }
            setTimeout(() => {
              //refreshes the invoices to get accurate data that represents the newly paid invoices
              this.setInvoices({
                custId: this.custId,
                dateRange: null,
                status: this.status,
                sortBy: "",
                addInvoices: false,
                id: null,
              });
              this.successBitArr = [0, 0];
              this.clearInvoicesToPay();
            }, 4000);
          }
        });
      }
    },
    receiveToken(event) {
      if (event.origin.includes("gateway.total-computing.com")) {
        var token = event.data;
        if (token.success == true) {
          this.success = true;
          this.token = token.message;
          this.expiry = token.expiry;
          this.displayIframe = !this.displayIframe;
          if(this.ccList !== undefined) {
            if(this.ccList.find(el => el.credit_card_id === token.message && el.credit_card_exp === this.expiry) === undefined) {
              this.ccList.push({credit_card_id: token.message, credit_card_exp: this.expiry})
            }
          }
          else {
            this.ccList = [{credit_card_id: token.message, credit_card_exp: this.expiry}]
          }
          
          
          this.selectedCC = {credit_card_id: this.token, credit_card_exp: this.expiry}
        } else if (token.success == false) {
          this.addNotification({
            type: "error",
            message: token.errorMessage,
          });
          document.getElementById("iframe").src += "";
        }
      }
    },
    removeInvoice(inv) {
      this.removeInvoiceToPay(inv);
    },
  },
  computed: {
    ...mapState(["payment", "invoice", "session"]),
    ...mapGetters({
      getACHFee: "control/getACHFee",
      getCCFee: "control/getCCFee",
      getUser: "session/getUser",
      getCust: "customer/getCust"
    }),
    total() {
      let sum = 0;
      this.invoice.invoicesToPay.forEach((inv) => {
        sum += +inv.balance;
      });
      return Math.round(sum * 100) / 100;
    },
  },
});
</script>
