
import { Options, Vue } from "vue-class-component";
import Invoice from "@/components/Invoice.vue";

@Options({
  components: {
    Invoice,
  },
})
export default class Home extends Vue {}
